<style lang="scss">
  .select-box{
    border:1px solid #d1d1d1;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
    border-radius:4px;
    @apply text-center;
    color:#000;
    padding:20px;
    margin: 0 auto;
    width:330px;

    .sticker{
      position:absolute;
      top:-25px;
      left:-25px;
      background:#FAB413;
      border-radius:100%;
      padding:18px 12px;
      font-size:12px;
      font-weight:900;
      transform:rotate(-30deg);
      text-align:center;
    }

    .green-btn{
      padding-right:30px;
      padding-left:30px;
    }

    &.active{
      border: 2px solid #FAB413;
    }
    ul{
      text-align:left;
      font-size:16px;
      margin-left:45px;
      li{
        margin-bottom:10px;
      }
    }
  }
  .history{
    border:1px solid #D1D1D1;
    border-radius:4px;
    .header{
      background:#FBFBFB;
      padding:10px 15px;
      border-radius:4px 4px 0 0;
      border-bottom:1px solid #EDEDED;
    }
    .billing{
      display:flex;
      border-bottom:1px solid #EDEDED;
      padding:15px;
      &:last-child{
        border:none;
      }
      &.hidden{
        display:none;
      }
    }

    .referral-list{


      &:last-child{
        border:none;
      }

      .referral{
         padding:15px;
         display:flex;
         border-bottom:1px solid #EDEDED;

        &.hidden{
          display:none;
        }
      }
    }

    .showMore{
      border-top:1px solid #EDEDED;
      border-bottom:none;
    }
  }
</style>

<template>
  <div class="lg:mt-5 lg:px-4 max-w-2xl mx-auto side-nav short">
    <vue-headful
      title="Settings - Referral | Octoa"
    />

    <div class="flex flex-wrap mb-5">
      <div class="w-full lg:w-1/4">
        <setting-menu />
      </div>

      <div class="w-full lg:w-3/4">
        <div
          v-if="loading"
          class="text-center"
        >
          <img
            src="@/assets/img/icons/loader.svg"
            width="50"
            class="mt-32"
          >
        </div>
        <div
          v-else
          class="pb-20"
        >
          <div class="bg-white h-full mx-4 my-4 px-4 py-4 lg:mx-0 lg:my-0 lg:px-8 lg:py-8 border-grey-lighter border rounded">
            <h2 class="font-bold mb-5">
              Referral
            </h2>

            <p class="leading-loosen">
              With the referral program of Octoa you can earn money for each paid customer you bring in. Use the link to share with your community and friends.<br><br> Earn up to €119 per referral. For every paying customer we share 30% of the revenue for 24 months!
            </p>

            <div class="flex mt-3 flex-wrap items-center">
              <div class="w-full md:w-1/2 lg:w-7/12">
                <input
                  type="text"
                  readonly="readonly"
                  :value="link"
                >
              </div>
              <div class="w-full mt-6 md:mt-0 md:w-1/2 lg:w-5/12">
                <a
                  class="green-btn  md:ml-4"
                  @click="copyLink()"
                >Copy referral link</a>
              </div>
            </div>

            <div class="mt-10 flex justify-between items-center">
              <div>
                <h2 class="font-bold">
                  Referral stats
                </h2>
              </div>
              <div>
                <button
                  v-if="!hasRequestedPayout && referrals.total_earnings > 0"
                  class="green-btn w-full"
                  @click="requestPayout"
                >
                  Request pay out
                </button>
              </div>
            </div>
            <div
              class="mt-3 mb-5"
            >
              <div class="history">
                <div class="billing flex-wrap items-center">
                  <div class="w-full md:w-3/12">
                    Clicks
                  </div>
                  <div class="w-9/12 md:w-7/12 md:px-4">
                    Number of times your link has been clicked.
                  </div>
                  <div class="w-3/12 md:w-2/12 text-right font-bold">
                    {{ referrals.numbers_link_clicked }}
                  </div>
                </div>
                <div class="billing flex-wrap items-center">
                  <div class="w-full md:w-3/12">
                    Referrals
                  </div>
                  <div class="w-9/12 md:w-7/12 md:px-4">
                    People who have signed up using your link.
                  </div>
                  <div class="w-3/12 md:w-2/12 text-right font-bold">
                    {{ referrals.total_referrals }}
                  </div>
                </div>
                <div class="billing flex-wrap items-center">
                  <div class="w-full md:w-3/12">
                    Paid referrals
                  </div>
                  <div class="w-9/12 md:w-7/12 md:px-4">
                    People who took a paid subscription.
                  </div>
                  <div class="w-3/12 md:w-2/12 text-right font-bold">
                    {{ referrals.total_paid_referrals }}
                  </div>
                </div>
                <div class="billing flex-wrap items-center">
                  <div class="w-full md:w-3/12">
                    Total earnings
                  </div>
                  <div class="w-9/12 md:w-7/12 md:px-4">
                    From paid referrals.
                  </div>
                  <div class="w-3/12 md:w-2/12 text-right font-bold">
                    <vue-numeric
                      currency-symbol-position="prefix"
                      precision="2"
                      read-only
                      currency="€"
                      decimal-separator=","
                      thousand-separator="."
                      min="0"
                      :value="referrals.total_earnings"
                    />
                  </div>
                </div>
                <div class="billing flex-wrap items-center">
                  <div class="w-full md:w-3/12">
                    Total payout
                  </div>
                  <div class="w-9/12 md:w-7/12 md:px-4">
                    Payout from earnings.
                  </div>
                  <div class="w-3/12 md:w-2/12 text-right font-bold">
                    <vue-numeric
                      currency-symbol-position="prefix"
                      precision="2"
                      read-only
                      currency="€"
                      decimal-separator=","
                      thousand-separator="."
                      min="0"
                      :value="referrals.total_payout"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div v-if="referrals.total_paid_referrals > 0">
              <h2 class="font-bold mt-10">
                People with paid subscription
              </h2>

              <div
                class="mt-3 mb-5"
              >
                <div class="history">
                  <div class="header hidden md:flex">
                    <div class="w-1/2 lg:w-1/4">
                      Name
                    </div>
                    <div class="w-1/2 lg:w-1/4">
                      Subscribed date
                    </div>
                    <div class="w-1/2 lg:w-1/4">
                      Status
                    </div>
                    <div class="w-1/2 lg:w-1/4">
                      Total payout
                    </div>
                  </div>
                  <div
                    v-for="(company, index) in referrals.company_paid_subscription"
                    :key="index"
                    class="referral-list"
                  >
                    <div
                      v-if="company.is_subscription_active || company.subscription_end_at"
                      class="referral items-center flex-wrap leading-normal"
                      :class="{'hidden': (!showMoreBilling && index > 3) }"
                    >
                      <div class="w-1/2 lg:w-1/4 mb-4 md:mb-0">
                        <div class="block md:hidden font-bold">
                          Name
                        </div>
                        {{ company.users[0].full_name }}
                      </div>
                      <div class="w-1/2 lg:w-1/4 mb-4 md:mb-0">
                        <div class="block md:hidden font-bold">
                          Subscribed date
                        </div>
                        {{ company.subscription_start_at | humanDate(timezone) }}
                      </div>
                      <div class="w-1/2 lg:w-1/4">
                        <span
                          v-if="company.is_subscription_active"
                          class="capitalize"
                        >
                          <div class="block md:hidden font-bold">Status</div>
                          {{ company.subscription_terms }}
                        </span>
                        <span v-else>
                          <div class="block md:hidden font-bold">Status</div>
                          Cancelled
                        </span>
                      </div>
                      <div class="w-1/2 lg:w-1/4">
                        <div class="block md:hidden font-bold">
                          Total payout
                        </div>
                        <vue-numeric
                          currency-symbol-position="prefix"
                          precision="2"
                          read-only
                          currency="€"
                          decimal-separator=","
                          thousand-separator="."
                          min="0"
                          :value="company.sum_of_payment"
                        />
                      </div>
                    </div>
                    <div
                      v-if="!showMoreBilling && (index == 3 && referrals.company_paid_subscription.length > 4)"
                      class="referral showMore"
                    >
                      <div
                        class="w-full text-center text-green underline cursor-pointer"
                        @click="viewMoreBilling"
                      >
                        View more
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import auth from '@/auth'
import config from '@/config'

export default {
  name: 'SettingsReferral',
  data(){
    return {
      loading: true,
      domain: 'https://octoa.com',
      link: '',
      user: auth.user(),
      timezone: '',
      referrals: {},
      showMoreBilling: false,
      paymentFail: false,
      hasRequestedPayout:false,
    }
  },
  created(){
    this.currency = this.user.companySettings.currency
    this.timezone = this.user.companySettings.timezone
  },
  async mounted(){

    if(window.location.hostname == 'staging-app.octoa.com'){
      this.domain = 'https://staging.octoa.com'
    }
    this.link = `${this.domain}/?refcode=${this.user.company.referral_code}`

    if(!this.user.company.is_ambassador && !this.user.company.is_referrer){
      this.$router.push('/settings')
    }

    if(this.user.companySettings.isRequestPayout){
      this.hasRequestedPayout = true
    }

    try {
      const { data } =   await this.$api.get('referral').all(this.user.company.id)
      this.referrals = data
    } catch (e) {
      this.$toasted.global.api_error(e)
    }

    this.loading = false
  },
  methods: {
    async requestPayout(){
      const can = await this.$alert.confirm({
        title: 'Requesting a pay out? We will contact you for more details.',
        text: 'Once a pay out is complete you will see a credit invoice inside your account.',
      })

      if (can) {
        try {
          const { data } = await this.$api.get('company').requestPayout(this.user.company.id)
          this.$toasted.global.general_success({
            message : 'Pay out requested.'
          })
          await this.$store.dispatch('company/refreshCompanyDetails')
          this.hasRequestedPayout = true
        } catch(e){
          this.$toasted.global.api_error(e)
        }
      }
    },
    viewMoreBilling(){
      this.showMoreBilling = true
    },
    copyLink(){
      const link = this.link
      let dummy = document.createElement('textarea')
      document.body.appendChild(dummy)
      dummy.value = link
      dummy.select()
      document.execCommand('copy')
      document.body.removeChild(dummy)

      this.$toasted.global.general_success({
        message : 'Referral link has been copied to your clipboard.'
      })
    },
  },
}
</script>
